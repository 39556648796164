@use "./custom" as *;

// import commponent
@use "components/_buttons.scss";
@use "components/_cards.scss";

//import sections
@use "sections/_sidebar.scss";
@use "sections/_settings.scss";
@use "sections/_choose-logo.scss";

* {
  text-decoration: none !important;
  list-style: none !important;
  font-family: $poppins;
}

html,
body,
main {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  background-color: $black-200 !important;
}

main {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.container {
  max-width: 1224px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
}

.text-highlight {
  color: $green;
}

.main__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2.5625rem 2rem;
  gap: 1.6875rem;
  width: 100%;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(1, auto);
  }
}

.mobil-nav-toggler {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.react-select__menu .react-select__option, .react-select__menu-list {
  background: #232323 !important;
  color: white !important;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, .6) !important;
  }
}

.react-select__single-value {
  color: white !important;
}

.react-select-container, .react-select__control {
  color: white !important;
  background: #232323 !important;
}